import { Text, Box } from 'wikr-core-components';
import useI18next from 'services/i18n';
import { ERROR_LEVELS, SENTRY_CABINET } from 'sentry-utils';
import { useRouteError } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { SeverityLevel } from '@sentry/react';

import sentry from 'services/Sentry/SentryInstance';
import { errorType, Extras, IError } from 'services/Sentry/interfaces';

import OutlinedButton from 'components/OutlinedButton';
import ContactUsLink from 'components/ContactUsLink';

import * as S from './styled';

export const sentryCaptureEvent = (error: Error, type: errorType, level: SeverityLevel, extra?: Extras): void => {
    // @ts-ignore
    sentry.logError(error, type, level, extra);
};

export const ErrorPage = () => {
    const { t } = useTranslation();

    useI18next();

    const error = useRouteError();

    useEffect(() => {
        sentry.logError(error as IError, SENTRY_CABINET, ERROR_LEVELS.CRITICAL);
    }, [error]);

    return (
        <S.ErrorBoundaryWrapper>
            <Box>
                <Text type="h1" center text="😵‍💫" mb={20} dataLocator="wentWrongPageEmoji" />
                <Text type="h5" center text={t('errorPage.title')} mb={12} dataLocator="wentWrongPageTitle" />
                <Text
                    color="text-secondary"
                    text={t('errorPage.subtitle')}
                    center
                    mb={32}
                    dataLocator="wentWrongPageSubTitle"
                />
                <OutlinedButton
                    text={t('basics.tryAgain')}
                    dataLocator="wentWrongPageTryAgainButton"
                    onClick={() => window.location.reload()}
                    mb={20}
                />
                <ContactUsLink dataLocator="wentWrongPageContactSupportButton" />
            </Box>
        </S.ErrorBoundaryWrapper>
    );
};
