import { ActionEventAction } from 'wikr-core-analytics';

import config from 'appConfig';

import { getFaqLinks, getPolicyLink } from 'helpers/getFAQLink';
import { getDeviceOs } from 'helpers/getDeviceOs';
import { useGetCFLink } from 'hooks/common/useGetCFLink';

const deviceOs = getDeviceOs();

export const useGetSecondarySidebarLinks = () => {
    const cfLink = useGetCFLink();

    return [
        {
            to: getFaqLinks(deviceOs),
            title: 'sidebar.faq',
            dataLocator: 'burgerFAQ',
            target: '_blank',
            rel: 'noopener noreferrer',
            withSvg: true,
            analyticActionEvent: ActionEventAction.faq,
        },
        {
            to: cfLink,
            title: 'basics.contactUs',
            dataLocator: 'contactUsLink',
            target: '_blank',
            rel: 'noopener noreferrer',
            withSvg: true,
            analyticActionEvent: ActionEventAction.contactUs,
        },
        {
            to: getPolicyLink(config.links.termsOfUse),
            title: 'sidebar.terms',
            dataLocator: 'burgerTermsOfUse',
            target: '_blank',
            rel: 'noopener noreferrer',
            analyticActionEvent: ActionEventAction.termsOfUse,
        },
        {
            to: getPolicyLink(config.links.privacyPolicy),
            title: 'sidebar.privacy',
            dataLocator: 'burgerPrivacyPolicy',
            target: '_blank',
            rel: 'noopener noreferrer',
            analyticActionEvent: ActionEventAction.privacyPolicy,
        },
        {
            to: getPolicyLink(config.links.refundPolicy),
            title: 'sidebar.refund',
            dataLocator: 'burgerRefundPolicy',
            target: '_blank',
            rel: 'noopener noreferrer',
            analyticActionEvent: ActionEventAction.refundPolicy,
        },
    ];
};
