import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Text } from 'wikr-core-components';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';
import Modal from 'components/Modal';
import FeedbackModalsNavBar from 'components/FeedbackModalsNavBar';
import ContactUsLink from 'components/ContactUsLink';

import { IErrorFeedbackModalProps } from '../../types';

const ErrorFeedbackModal = ({
    img,
    title,
    mainBtnText,
    mainBtnDataLocator,
    onMainBtnClick,
    supportLinkDataLocator,
    onSupportLinkClick,
}: IErrorFeedbackModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal dataLocator="updatingSubscriptionPlanModal" isOpen style={{ content: { padding: '16px' } }}>
            <FeedbackModalsNavBar dataLocator="updatingSubscriptionPlanCloseButton" />
            <Image maxWidth={72} center alt="emoji" src={img} isForceVisible />
            <Text type="h5" text={title} mb={24} center />
            <SecondaryDefaultButton
                dataLocator={mainBtnDataLocator}
                text={mainBtnText}
                onClick={onMainBtnClick}
                mb={24}
            />
            <ContactUsLink
                text={t('basics.contactSupport')}
                dataLocator={supportLinkDataLocator}
                onClick={onSupportLinkClick}
            />
        </Modal>
    );
};

export default ErrorFeedbackModal;
