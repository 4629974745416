import { Text, Box, Image } from 'wikr-core-components';
import { ActionEventAction } from 'wikr-core-analytics';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { selectUserFirstName } from 'store/user/selectors';
import { logout } from 'store/auth/actions';

import { PATHS } from 'constants/routerPaths';
import { useGetSecondarySidebarLinks } from './hooks';

import { sendAnalyticSideBarElementClick, sendAnalyticSideBarLogoutClick } from 'services/analytics';

import { useToggle, useDispatch, useSelector } from 'hooks';

import { getWindowSize } from 'helpers/getWindowSize';

import SideBarNavLink from './components/SidebarNavLink';
import SidebarNavButton from './components/SidebarNavButton';

import * as S from './styled';

import avatar from './img/Avatar.webp';

import usePrimaryLinks from './usePrimaryLinks';

const isDesktop = getWindowSize(1240);

export default function SideBar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { filteredPrimarySideBarLinks } = usePrimaryLinks();

    const sidebarSecondaryLinks = useGetSecondarySidebarLinks();

    const firstName = useSelector(selectUserFirstName);

    const { isOpen, close } = useToggle();

    const navigate = useNavigate();

    const handleLinkClick = (analyticActionEvent?: ActionEventAction) => {
        analyticActionEvent && sendAnalyticSideBarElementClick(analyticActionEvent);

        close();
    };

    const handleLogout = () => {
        sendAnalyticSideBarLogoutClick();
        dispatch(logout());
        navigate('/');
        close();
    };

    const handleAvatarRedirect = () => {
        navigate(PATHS.ACCOUNT_DEFAULT);
        close();
    };

    return (
        <S.SideBarWrapper isOpen={isOpen} isDesktop={isDesktop}>
            {!isDesktop && <S.SideBarOverlay onClick={close} />}
            <S.SideBarMenu>
                <Box paddingTop={32} paddingX={12}>
                    <S.User>
                        <S.Avatar onClick={handleAvatarRedirect}>
                            <Image alt="avatar" src={avatar} maxWidth={40} dataLocator="burgerUserAvatar" />
                        </S.Avatar>
                        <Text medium text={firstName || ''} dataLocator="burgerUsername" />
                    </S.User>
                    {filteredPrimarySideBarLinks.map(({ to, title, icon, dataLocator, analyticActionEvent }) => (
                        <SidebarNavButton
                            key={dataLocator}
                            // @ts-ignore
                            onClick={() => handleLinkClick(analyticActionEvent)}
                            to={to}
                            title={title}
                            icon={icon}
                            dataLocator={dataLocator}
                        />
                    ))}
                </Box>

                <Box paddingX={12}>
                    {sidebarSecondaryLinks.map(({ dataLocator, analyticActionEvent, ...rest }) => (
                        <SideBarNavLink
                            key={dataLocator}
                            onClick={() => handleLinkClick(analyticActionEvent)}
                            dataLocator={dataLocator}
                            {...rest}
                        />
                    ))}

                    <SideBarNavLink onClick={handleLogout} dataLocator="burgerLogoutBtn" title={t('sidebar.logout')} />
                </Box>
            </S.SideBarMenu>
        </S.SideBarWrapper>
    );
}
