import { Box } from 'wikr-core-components';
import React from 'react';

import { selectIsCalifornia } from 'store/user/selectors';

import { useScreenLoad } from 'services/analytics/useScreenLoad';

import { useSubscriptions, useRestoreSubscription, useSelector } from 'hooks';

import ContactUsLink from 'components/ContactUsLink';
import SubscriptionsSkeleton from './components/SubscriptionsSkeleton';
import SubscriptionCard from './components/SubscriptionCard';

import { ContactUsWrapper } from './styled';

import { ISubscription } from 'types/subscription';

import useCancelOfferFlow from './useCancelOfferFlow';
import useCaliforniaCancelOfferFlow from './useCaliforniaCancelOfferFlow';

const SubscriptionsTab = () => {
    useScreenLoad();

    const isCalifornia = useSelector(selectIsCalifornia);
    const { isLoading, subscriptions } = useSubscriptions();
    const { onCancelSubscriptionFlow } = useCancelOfferFlow();
    const { onCaliforniaCancelSubscriptionFlow } = useCaliforniaCancelOfferFlow();
    const { showRestoreCandidate } = useRestoreSubscription();

    const cancelFlowHandler = isCalifornia ? onCaliforniaCancelSubscriptionFlow : onCancelSubscriptionFlow;

    const displaySkeleton = isLoading && !subscriptions?.length;

    return (
        <Box paddingTop={40} paddingBottom={40}>
            {displaySkeleton && <SubscriptionsSkeleton />}

            {!displaySkeleton &&
                subscriptions.map((subscription: ISubscription) => (
                    <SubscriptionCard
                        key={subscription.id}
                        subscription={subscription}
                        onCancelSubscription={cancelFlowHandler}
                        showRestoreCandidate={showRestoreCandidate}
                    />
                ))}

            <ContactUsWrapper>
                <ContactUsLink data-locator="subscriptionFaqBtn" />
            </ContactUsWrapper>
        </Box>
    );
};

export default SubscriptionsTab;
