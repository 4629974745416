import React from 'react';
import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useGetCFLink } from 'hooks/common/useGetCFLink';

interface ContactUsLinkProps {
    dataLocator?: string;
    onClick?: () => void;
    text?: string;
}

const ContactUsLink = ({ onClick, dataLocator, text }: ContactUsLinkProps) => {
    const { t } = useTranslation();
    const link = useGetCFLink();

    return (
        <a target="_blank" rel="noopener noreferrer" onClick={onClick} data-locator={dataLocator} href={link}>
            <Text type="overline" center bold text={text || t('basics.contactUs')} />
        </a>
    );
};

export default ContactUsLink;
