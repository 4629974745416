import { Image, Text } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FORGOT_PASSWORD } from 'store/user/actionTypes';
import { createProcessingSelector } from 'store/loading/selectors';

import { PATHS } from 'constants/routerPaths';

import {
    sendAnalyticResetLoginButtonClick,
    sendAnalyticResendPasswordClick,
    sendAnalyticResetContactUsClick,
} from 'services/analytics';

import { useSelector } from 'hooks';

import OutlinedButton from 'components/OutlinedButton';
import ContactUsLink from 'components/ContactUsLink';

import * as S from './styled';

import checkMailBoxIcon from 'assets/img/checkMailbox.svg';

const CheckMailBox = ({ email, onResendPassword }: { email: string; onResendPassword: () => void }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isLoading: boolean = useSelector(createProcessingSelector([FORGOT_PASSWORD]));

    const handleLogin = () => {
        sendAnalyticResetLoginButtonClick();

        navigate(PATHS.LOGIN);
    };

    const onResendPasswordHandler = () => {
        sendAnalyticResendPasswordClick();

        onResendPassword();
    };

    return (
        <S.CheckMailBox paddingX={16}>
            <Image maxWidth={48} center alt="checkMailBoxIcon" src={checkMailBoxIcon} mb={24} isForceVisible />
            <Text type="h5" bold text={t('forgotPassword.checkMailBox')} mb={12} center />
            <Text text={t('forgotPassword.passwordWasSent')} center />
            {email && <Text bold text={email} mb={32} center />}
            <S.LogInButton text={t('basics.login')} mb={12} onClick={handleLogin} dataLocator="loginButton" />
            <OutlinedButton
                text={t('forgotPassword.resendPassword')}
                onClick={onResendPasswordHandler}
                mb={28}
                isLoading={isLoading}
                dataLocator="resendPasswordBtn"
            />
            <ContactUsLink onClick={sendAnalyticResetContactUsClick} />
        </S.CheckMailBox>
    );
};

export default CheckMailBox;
