import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as LogoNew } from 'assets/images/logo-16-8.svg';

const baseLogo = css`
    display: block;
    height: 100%;
    width: auto;
`;

export const LogoContainer = styled(Link)`
    height: 24px;
`;

export const LogoImage = styled(Logo)`
    ${baseLogo};
`;

export const LogoNewImage = styled(LogoNew)`
    ${baseLogo};
`;
