import { FC } from 'react';

import { isAuthenticatedSelector } from 'store/auth/selectors';

import { PATHS } from 'constants/routerPaths';

import { useSelector } from 'hooks';

import * as S from './styled';

const Logo: FC = () => {
    const isAuthenticated = useSelector(isAuthenticatedSelector);

    const logoLink = isAuthenticated ? PATHS.MAIN : PATHS.LOGIN;

    return (
        <S.LogoContainer to={logoLink}>
            <S.LogoNewImage />
        </S.LogoContainer>
    );
};

export default Logo;
